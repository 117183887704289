import redirectToLogin from './redirectToLogin';

const xhrFetch = (url, method, args = {}) => xhrFetchResponse(url, method, args).then(res => res.status === 204 ? null : res.json());

export const xhrFetchResponse = (url, method, args = {}) => {
  const { method: _, headers, body, ...rest } = args;
  const options = {
    method,
    mode: 'cors',
    credentials: 'include',
    headers: new Headers({
      'Content-Type': 'application/json',
      ...(headers || {})
    }),
    ...rest
  };

  let search = '';

  if (body) {
    if (method === 'GET') {
      search = new URLSearchParams(body).toString();
    } else {
      options.body = JSON.stringify(body);
    }
  }

  return fetch(
    `${url}${(search && `?${search}`) || ''}`,
    options
  )
    .then(res => {
      if (!res.ok) {
        if (res.status === 401) {
          redirectToLogin();
        }

        throw res;
      }

      return res
    });
}
  
export const GET = (path, options) => xhrFetch(path, 'GET', options);
export const POST = (path, options) => xhrFetch(path, 'POST', options);
export const PATCH = (path, options) => xhrFetch(path, 'PATCH', options);
export const PUT = (path, options) => xhrFetch(path, 'PUT', options);
