import React from "react";
import cx from 'classnames';

import styles from './ToolTip.module.css';

/**
 * @param {string} content      Tooltip content to be displayed on hover.
 * @param {string} position     Sets the position of tooltip possible position options [top, bottom, right, left] default position bottom.
 * @param {string} className
 * @param {*} children
 * @returns Returns child       Component enclosed within a div with tool tip over the child component.
 */
const ToolTip = ({ content, position = 'bottom', className, children }) => (
    <div className={cx(styles.tooltip, styles[position], className)}>
        <div className={cx(styles.wrapper)} data-tooltip={content}>
            {children}
        </div>
    </div>
);

export default ToolTip;
