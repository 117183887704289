/**
 * Get currency symobol
 * @param {string} currency ISO 4217 currency codes defualt is GBP.
 * @param {string} locale Language tag default is en-GB.
 * @returns Returns ISO currency code based on locale.
 */
const getCurrencySymbol = (
  currency = 'GBP',
  locale = navigator.locale || 'en-GB',
) => {
  try {
    return new Intl.NumberFormat(
      locale,
      {
        style: 'currency',
        currency: currency
      }
    )
      .formatToParts(0)
      .find(({ type, value }) => type === 'currency')
      .value;
  } catch {
    return null;
  }
};

export default getCurrencySymbol;
