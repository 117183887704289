import React, { useMemo } from 'react';
import cx from 'classnames';
import { useFormContext } from 'react-hook-form';

import NoteAndError from '../NoteAndError';
import styles from './RadioGroup.module.css';

/**
 * @param {String}  label               Radio group label
 * @param {String}  name                HTML input field name
 * @param {String}  note                Field's note
 * @param {Object}  rules               Field's rules object { key: value }. key is the rule name and value can be the error string or an object with value and message
 * @param {Array}   items               Radio group items array. Each item object should contain label, value and className. Value can be String or HTML
 * @param {String}  className           Extra class name to add to the input field
 * @param {String}  itemClassName       Extra class name to styles radio group items
 * @param {String}  variant             RadioGroup variant [default, button] *default
 * @param {Object}  rest                Object with other input attributes
 * @return {HTMLElement}
 */
const RadioGroup = ({
  label,
  name,
  note,
  rules,
  items,
  className,
  itemClassName,
  variant = 'default',
  ...rest }) => {
    // @deprecated - Please use the RadioGroup component from @operation-diameter
    if (process.env.REACT_APP_HONEY_BADGER_ENV !== 'production') {
      console.warn(
        'RadioGroup component from Shared is deprecated. Please use the RadioGroup component from @operation-diameter'
      );
    }

    const { register, getFieldState, formState } = useFormContext();
    const error = useMemo(
      () => getFieldState(name, formState).error,
      [name, formState, getFieldState]
    );

    return (
      <div className={cx(styles.radio, { [styles.error]: error }, className)}>
        {label && (
          <label className={styles.label} htmlFor={name}>
            {label}
          </label>
        )}
        {items.length && (
          <div
            className={cx(
              styles.items,
              { [styles.button]: variant === 'button' },
              itemClassName
            )}
          >
            {items.map(({ label, value, disabled = false, className }) => (
              <div
                key={`${name}-${value}`}
                className={cx(styles.input, {
                  [styles.button]: variant === 'button'
                })}
                data-testid='radio-input'
              >
                <input
                  type='radio'
                  name={name}
                  id={`${name}-${value}`}
                  value={value}
                  disabled={disabled}
                  className={cx(styles.field, className)}
                  {...register(name, rules)}
                  {...rest}
                />
                <label htmlFor={`${name}-${value}`}>{label}</label>
              </div>
            ))}
          </div>
        )}
        <NoteAndError note={note} error={error} />
      </div>
    );
  };

export default RadioGroup;
