import React, { lazy, useEffect, useState } from 'react';

import Text from '../Text';
import Link from '../Link';

import { ReactComponent as TrustpilotLogo } from './trustpilot-logo.svg';
import { loadTrustpilotData, TRUSTPILOT_REVIEWS_URL } from './actions.js';

import styles from './Trustpilot.module.css';

const TrustpilotRating = lazy(() => import('./Rating'));

const formatTotalValue = (value, locale = 'en-GB') => new Intl.NumberFormat(locale).format(value);

const Trustpilot = () => {
  // @deprecated - Please use the Trustpilot component from @operation-diameter
  if (process.env.REACT_APP_HONEY_BADGER_ENV !== 'production') {
    console.warn(
      'Trustpilot component from Shared is deprecated. Please use the Trustpilot component from @operation-diameter'
    );
  }

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    loadTrustpilotData()
      .then(data => {
        setData(data);
        setLoading(false);
      });
  }, []);

  return (
    <div className={styles.trustpilot} data-testid="trustpilot">
      {loading && <span>Loading Trustpilot</span>}
      {!loading && (
        <>
          <TrustpilotRating stars={data?.score?.stars} />
          <div className={styles.reviews}>
            <Text className={styles.label}>
              {formatTotalValue(data?.numberOfReviews?.total)} reviews
            </Text>
          </div>
          <Link
            to={TRUSTPILOT_REVIEWS_URL}
            target="_blank"
            className={styles.logo}
          >
            <TrustpilotLogo />
          </Link>
        </>
      )}
    </div>
  );
};

export default Trustpilot;
