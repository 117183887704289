import React from 'react';
import Input from '../Input';
import cx from 'classnames';

import getCurrencySymbol from '../../helpers/getCurrencySymbol';

import styles from './CurrencyInput.module.css';

/**
 * @param {string} label    String with the field's label
 * @param {string} name     Field's name
 * @param {string} note     Field's note
 * @param {object} rules    Field's rules object { key: value }. key is the rule name and value can be the error string or an object with value and message
 * @param {int} min         Field's minimum value
 * @param {int} max         Field's maximum value
 * @param {any} rest        Other input attributes
 * @return {HTMLElement}
 */
const CurrencyInput = ({
  label,
  name,
  note,
  rules,
  min,
  max,
  className,
  ...rest
}) => {
  // @deprecated - Please use the CurrencyInput component from @operation-diameter
  if (process.env.REACT_APP_HONEY_BADGER_ENV !== 'production') {
    console.warn(
      'CurrencyInput component from Shared is deprecated. Please use the CurrencyInput component from @operation-diameter'
    );
  }

  return (
    <Input
      type="number"
      className={cx(styles.field, className)}
      label={label}
      name={name}
      note={note}
      rules={rules}
      min={min}
      max={max}
      {...rest}
    >
      <span className={styles.currency}>{getCurrencySymbol()}</span>
    </Input>
  )
}
export default CurrencyInput;
