import React from 'react';
import cx from 'classnames';

import styles from './Heading.module.css';

/**
 * Heading tag
 * @type {Object}
 * @property {number} type default is 1 [1, 2, 3, 4, 5, 6]].
 * @property {boolean} alt Alternative style, default is false.
 * @property {*} accent Add accent to heading, default is false [false, left, center].
 * @property {string} title heading tag value
 * @property {string} className custom class for heading tag
 * @returns Returns heading tag based on type
 */
const Heading = ({
  type = 1,
  alt = false,
  accent = false,
  title,
  className,
  ...rest
}) => {
  // @deprecated - Please use the Heading component from @operation-diameter
  if (process.env.REACT_APP_HONEY_BADGER_ENV !== 'production') {
    console.warn(
      'Heading component from Shared is deprecated. Please use the Heading component from @operation-diameter'
    );
  }
  
  const Tag = `h${type}`;

  return (title && (
    <Tag className={cx(
        styles.heading,
        styles[`heading-${type}`],
        {
          [styles[`heading-${type}-alt`]]: alt,
          [styles[`accent`]]: accent,
          [styles[`accent-${accent}`]]: accent === 'center'
        },
        className
      )}
      {...rest}>
      {title}
    </Tag>
  )) || null;
}

export default Heading;
