import React from 'react';

import Form from './components/Form';
import SearchInput from './components/SearchInput';

import cx from 'classnames';

import styles from './SearchInputExample.module.css';

const Option = ({ heading, description, isSelected }) => (
  <div
    className={cx(styles.companyOption, { [styles.highlighted]: isSelected })}
  >
    <div className={styles.heading}>{heading}</div>
    {description && <div className={styles.description}>{description}</div>}
  </div>
);

// Mock data to be replaced when Company
const companies = [
  { heading: 'Black', description: 'This is the color Black' },
  { heading: 'Red', description: 'This is the color Red' },
  { heading: 'Green', description: 'This is the color Green' },
  { heading: 'Blue', description: 'This is the color Blue' },
  { heading: 'Orange', description: 'This is the color Orange' },
  { heading: 'Purple', description: 'This is the color Purple' },
  { heading: 'Orchid', description: 'This is the color Orchid' },
  { heading: 'Aqua', description: 'This is the color Aqua' },
  { heading: 'Lime', description: 'This is the color Lime' },
  { heading: 'Gray', description: 'This is the color Gray' },
  { heading: 'Brown', description: 'This is the color Brown' },
  { heading: 'Teal', description: 'That is the color Teal' },
  { heading: 'Skyblue', description: 'This is the color Skyblue' },
  { heading: 'Pink' },
  { description: 'This is some color' }
];

const SearchInputExample = () => {
  const handleChange = (value) => {
    console.log({ change: value });
  };

  const handleSelect = (value) => {
    console.log({ selected: value });
  };

  const handleSubmit = async (data) => {
    console.log({ data });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <SearchInput
        options={companies}
        name='company'
        label='Search for a Color'
        searchBy={(option) => [option.heading, option.description]}
        getOptionValue={(option) => option.heading}
        getOptionLabel={(option) =>
          `${option?.heading ?? ''}${
            option?.description ? ` · ${option.description}` : ''
          }`
        }
        renderOption={(option, isSelected) => (
          <Option {...option} isSelected={isSelected} />
        )}
        rules={{
          required: 'Please enter a valid company name'
        }}
        onChange={handleChange}
        onOptionSelect={handleSelect}
        variant='animated'
        // loading={<span><b>Loading</b>...</span>}
      />
      <button type='submit'>Continue</button>
    </Form>
  );
};

export default SearchInputExample;
