import React from 'react';
import cx from 'classnames';

import styles from './Table.module.css';

/**
 * Table component
 * @type {Object}
 * @property {Array.<string>} headers Table header Indicates row name.
 * @property {Array.<Object>} items Table body Indicates column/cell name.
 * @property {*} itemComponent Indicates custom table column component.
 * @property {string} className className custom class for parent div.
 * @returns {*} Returns customized table list with items and header
 */
const Table = ({
  headers,
  items = [],
  itemComponent: C,
  className
}) => (C && (
  <div className={cx(styles.table, className)}>
    {headers?.length > 0 && (
      <div className={styles.header}>
        {headers?.map((header, index) => (
          <div
            key={index}
            className={styles.head}
          >
            {header}
          </div>
        ))}
      </div>
    )}
    <div className={styles.body}>
      {items?.length > 0 && items.map((item, index) => (
        <C
          key={item.id || index}
          className={styles.row}
          {...item}
        />
      ))}
      {!items?.length && (
        <span>No items</span>
      )}
    </div>
  </div>
)) || null;

export default Table;
