import React from "react";

import styles from './NavGroup.module.css';

const NavGroup = ({ header, children }) => (
  <div className={styles.navGroup}>
    <label className={styles.header}>{header}</label>
    {children && (
      <div className={styles.items}>
        {children}
      </div>
    )}
  </div>
);

export default NavGroup;
