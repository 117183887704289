import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { isExternalLink } from '../../utils';
import useAnalytics from '../Analytics';

import styles from './LinkComponent.module.css';

const TRACKER_NAME = 'borrower-portal';
const EVENT_TYPES = {
  event: ['eventCategory', 'eventAction', 'eventLabel', 'eventValue', 'nonInteraction'],
};

/**
 * Link react router dom element
 * @type {Object}
 * @property {string} to                 A location to redirect to, pathname can be any valid URL path that.
 * @property {string} className          className custom class name to add to the link.
 * @property {boolean} showIcon          show the arrow icon default is false.
 * @property {boolean} back              is this a backwards link?
 * @property {*} children                link content
 * @param {object} trackerObject         Custom tracker data to be sent to Google Analytics
 * @property {*} rest                    other props being passed to the link
 * @returns                              Returns Link router dom element, if external link return anchor tag
 */
const LinkComponent = ({
  to,
  className,
  showIcon = false,
  back,
  children,
  trackerObject,
  ...rest
}) => {
  // @deprecated - Please use the Link component from @operation-diameter
  if (process.env.REACT_APP_HONEY_BADGER_ENV !== 'production') {
    console.warn(
      'Link component from Shared is deprecated. Please use the Link component from @operation-diameter'
    );
  }

  const { sendToTracker } = useAnalytics();

  if (!to || !children) {
    return null;
  }

  const classNames = cx(styles.action, className, {
    [styles.showIcon]: showIcon,
    [styles.back]: back
  });

  const handleTracking = async (evt) => {
    let eventData = {
      eventCategory: 'Navigate',
      eventAction: 'Navigate',
      eventLabel: evt.target.innerText,
      eventValue: 1
    };

    if (trackerObject) {
      eventData = EVENT_TYPES.event.reduce(
        (acc, key) => ({
          ...acc,
          // Check if trackerObject contains only valid properties
          ...((trackerObject.hasOwnProperty(key) && { [key]: trackerObject[key] }) || {})
        }), {})
    }

    sendToTracker({
      name: TRACKER_NAME, event: 'event', data: eventData
    });
  }

  return isExternalLink(to)
    ? (
      <a
        className={cx(classNames, {
          [styles.external]: isExternalLink
        })}
        href={to}
        onClick={handleTracking}
        {...rest}
      >
        {children}
      </a>
    )
    : (
      <Link
        className={classNames}
        to={to}
        onClick={handleTracking}
        {...rest}
      >
        {children}
      </Link>
    )
}

export default LinkComponent;
