import React from 'react';
import cx from 'classnames';

import styles from './SectionHeader.module.css';

/**
 * SectionHeader
 * @type {Object}
 * @property {string} className custom class for div tag.
 * @property {*} children child component to be enclosed inside the div tag
 * @returns Groups a section with custom class, child component and adds a break at the end of section
 */
const SectionHeader = ({ className, children }) => (children && (
  <div className={cx(styles.header, className)}>
    {children}
  </div>
)) || null;

export default SectionHeader;
