import React, { useEffect, useCallback } from 'react';
import cx from 'classnames';

import Heading from '../Heading';
import Text from '../Text';

import styles from './Modal.module.css';
import {createPortal} from "react-dom";

/**
 * @param {string}    title           title to show
 * @param {string}    description     description to show
 * @param {elements}  actions         elements array with modal actions
 * @param {boolean}   closeIcon       should the modal show the close modal icon
 * @param {boolean}   showHeader      should the modal show the header
 * @param {string}    className       custom class properties
 * @param {function}  onClick         function to run when toggling modal
 * @param {html}      children        HTML child element
 * @return {element}
 */
const Modal = ({
  title,
  description,
  actions = false,
  closeIcon,
  showHeader = true,
  className,
  onClick,
  children
}) => {
  const toggleModal = useCallback(
    (evt) => {
      if (
        onClick &&
        ((evt.type === 'keyup' && evt.key === 'Escape') || evt.type === 'click')
      ) {
        onClick();
      }
    },
    [onClick]
  );

  useEffect(() => {
    if (!closeIcon) {
      return;
    }

    window.addEventListener('keyup', toggleModal);

    return () => window.removeEventListener('keyup', toggleModal);
  }, [toggleModal, closeIcon]);

  return createPortal(
    <dialog className={styles.dialog} open>
      <div className={styles.modal}>
        {showHeader && (
          <div className={styles.header}>
            {closeIcon && (
              <div className={styles.toggle} onClick={toggleModal} />
            )}
          </div>
        )}
        <section className={cx(styles.wrapper, className)}>
          {title && <Heading type='3' title={title} accent />}
          {description && (
            <Text className={styles.description}>{description}</Text>
          )}
          {children}
        </section>
        {actions && <div className={styles.actions}></div>}
      </div>
    </dialog>,
    document.body
  );
};

export default Modal;
