import cx from 'classnames';

import Heading from '../Heading';
import Icon from '../Icon';

import styles from './Notice.module.css';

const ICON_TYPE = {
  default: 'info',
  action: 'info',
  attention: 'info',
  success: 'tickCircleSolid',
  error: 'cancelCircleSolid'
};

/**
 * Notice
 * @param {string} title            Notice title
 * @param {boolean} showIcon        Show icon based on type
 * @param {string} iconType         Show icon based on type
 * @param {string} type             Possible values [default, action, attention, success, error]
 * @param {boolean} showClose       Show close button
 * @param {*} onCloseClick          Close button functionality
 * @param {*} children              Notice content
 * @param {string} className        class properties for notice content
 * @param {string} iconClassName    class properties for notice content
 */
const Notice = ({
  title,
  showIcon = false,
  iconType,
  type = 'default',
  showClose = false,
  onCloseClick,
  children,
  className,
  iconClassName
}) => {
  // @deprecated - Please use the Notice component from @operation-diameter
  if (process.env.REACT_APP_HONEY_BADGER_ENV !== 'production') {
    console.warn(
      'Notice component from Shared is deprecated. Please use the Notice component from @operation-diameter'
    );
  }

  return (
    <div className={cx(styles.notice, className, styles[type])}>
      {showIcon && ICON_TYPE.hasOwnProperty(type) && (
        <div className={cx(styles.icon, iconClassName)}>
          <Icon name={ICON_TYPE[iconType || type]} />
        </div>
      )}
      <div className={styles.wrapper}>
        {title && <Heading type={5} title={title} className={styles.title} />}
        {children && <div className={styles.content}>{children}</div>}
      </div>
      {showClose && (
        <div className={styles.closeIcon}>
          <Icon name='close' onClick={onCloseClick} />
        </div>
      )}
    </div>
  );
};

export default Notice;
