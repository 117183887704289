import React, { useEffect } from 'react';

import Heading from '../Heading';
import PageError from '../PageError';

import styles from './Error.module.css';

/**
 * Error
 * @param {boolean} showPageError   Show Page error [true, false] default is false
 * @param {string} title            Error title
 * @param {string} message          Info message for the error
 * @param {string} icon             Show icon svg
 * @param {string} iconClass        Icon className
 * @param {string} errorClass       Error wrapper className
 * @param {*} error                 Uncaught error
 * @param {*} children              Content
 */
const Error = ({ showPageError = false, title, message, icon, iconClass, errorClass, error, children }) => {

  useEffect(() => {
    if (error) {
      // Report to HoneyBadger
    }
  }, [error]);

  return (title && (
    <>
      {showPageError &&
        <PageError
          title={title}
          message={message}
          icon={icon}
          iconClass={iconClass}>
          {children}
        </PageError>}
      {!showPageError &&
        <div className={errorClass}>
          <Heading
            type="3"
            title={title}
            className={styles.header}
          />
          {children}
        </div>}
    </>
  )) || null;
};

export default Error;
