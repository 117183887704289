import { GET } from '../../helpers/xhr';

const TRUSTPILOT_URL = 'https://api.trustpilot.com/v1/business-units/4fa92f2600006400051522e5';
const TRUSTPILOT_APIKEY = 'y0WQnjukauzqs4qQGiql8VZRGtFmaOtn';
export const TRUSTPILOT_REVIEWS_URL = "https://uk.trustpilot.com/review/fundingcircle.com";

export const loadTrustpilotData = () =>
  GET(`${TRUSTPILOT_URL}?apikey=${TRUSTPILOT_APIKEY}`, {
    credentials: 'omit'
  });
