import React, { useMemo, useState } from 'react';
import cx from 'classnames';
import { useFormContext } from 'react-hook-form';

import styles from './Select.module.css';
import NoteAndError from '../NoteAndError';

/**
 * Select component
 * @param {String}   name         Name for the field
 * @param {String}   label        Label for the field
 * @param {Object[]} options      An array of objects with the format { value, label }
 * @param {String}   placeholder  Placeholder option to be added
 * @param {String}   note         Field's note
 * @param {Object}   rules        An object with rules for the field
 * @param {String}   className    A class name to overwrite the default styles
 * @param {Element}  children     Array of DOM Elements
 * @param {Function} onChange     Function to be called when the value changes
 * @param {Any}      rest         Other <select /> attributes
 * @returns {Element}             Select component.
 */
const Select = ({
  name,
  label,
  options = [],
  selected,
  placeholder,
  note = '',
  rules,
  className,
  children,
  onChange,
  ...rest
}) => {
  // @deprecated - Please use the Select component from @operation-diameter
  if (process.env.REACT_APP_HONEY_BADGER_ENV !== 'production') {
    console.warn(
      'Select component from Shared is deprecated. Please use the Select component from @operation-diameter'
    );
  }

  const [placeHolderClass, setPlaceHolderClass] = useState(placeholder ? true : false);
  const { register, getFieldState, formState } = useFormContext();
  const error = useMemo(
    () => getFieldState(name, formState).error,
    [name, formState, getFieldState]
  );

  const handleOnChange = (event) => {
    const { value } = event.target;

    setPlaceHolderClass(value ? false : true);

    if (onChange && typeof onChange === 'function') {
      onChange(event);
    }
  };

  return (
    (name && (
      <div className={cx(styles.wrapper, { [styles.error]: error }, className)}>
        {label && (
          <label className={styles.label} htmlFor={name}>
            {label}
          </label>
        )}
        <div className={styles.fieldWrapper}>
          <select
            name={name}
            id={name}
            value={selected}
            className={cx(styles.field, {
              [styles.placeholder]: placeHolderClass,
            })}
            {...register(name, rules)}
            {...rest}
            onChange={handleOnChange}
          >
            {placeholder && <option value="">{placeholder}</option>}
            {options.map(({ label, value, ...rest }) => (
              <option key={value} value={value} {...rest}>
                {label}
              </option>
            ))}
          </select>
          <div className={styles.chevron} />
        </div>
        {children}
        <NoteAndError note={note} error={error} />
      </div>
    )) ||
    null
  );
};

export default Select;
