import React from 'react';
import cx from 'classnames';

import styles from './Header.module.css';

const Header = ({children, className}) => {
  return (
    <div className={cx(styles.header, className)} data-testid="header">
      {children}
    </div>
  )
}

export default Header;
