import React, { useMemo } from 'react';
import cx from 'classnames';
import { useFormContext } from 'react-hook-form';

import styles from './Input.module.css';
import NoteAndError from '../NoteAndError';
import Label from '../Label';

/**
 * @param {HTMLELement} label             Input field's label
 * @param {String} name                   HTML input field name
 * @param {String} type                   HTML input field types - default textx
 * @param {String} note                   Field's note
 * @param {Object} rules                  Field's rules object { key: value }. key is the rule name and value can be the error string or an object with value and message
 * @param {String} className              Extra class name to add to the input field
 * @param {String} variant                Input variant [default, animated] *default
 * @param {boolean} noRegister            Whether this input was already registered. Defaults to "false"
 * @param {HTMLELement} children          HTMLElements to complement the input field
 * @param {Object} rest                   Object with other input attributes
 * @return {HTMLELement}
 */
const Input = ({
  label,
  name,
  type = 'text',
  note = '',
  rules,
  className,
  variant = 'default',
  noRegister = false,
  children,
  ...rest
}) => {
  const { register, getFieldState, formState } = useFormContext();
  const error = useMemo(
    () => getFieldState(name, formState).error,
    [name, formState, getFieldState]
  );

  return (
    <div
      className={cx(
        styles.wrapper,
        {
          [styles.error]: error,
          [styles.animated]: variant === 'animated'
        },
        className
      )}
    >
      <div className={styles.fieldWrapper}>
        <input
          type={type}
          name={name}
          id={name}
          className={styles.field}
          {...(!noRegister && register(name, rules))}
          {...rest}
          {...(variant === 'animated' && { placeholder: ' ' })}
        />
        {label && (
          <Label htmlFor={name} isError={!!error} variant={variant}>
            {label}
          </Label>
        )}
        {children}
      </div>
      <NoteAndError note={note} error={error} />
    </div>
  );
};

export default Input;
