import React from 'react';
import cx from 'classnames';

import styles from './InfoArea.module.css';

/**
 * @typedef {object}
 * @property {string} className custom class for div tag.
 * @property {} children child component to be enclosed inside the div tag
 * @returns Returns a div tag and groups child component, return empty if no child component is present.
 */
const InfoArea = ({ className, children }) => (children && (
  <div className={cx(styles.infoArea, className)}>
    {children}
  </div>
)) || null;

export default InfoArea;
