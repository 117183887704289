import React from "react";
import cx from "classnames";

import styles from "./Label.module.css";

/**
 * @param {String} htmlFor          Id of labeled control
 * @param {boolean} isError         Error status of labeled control
 * @param {String} className        Extra class name to add to the input field
 * @param {String} variant          Input variant [default, animated] *default
 * @param {HTMLELement} children    HTMLElements to complement the input field
 * @param {Object} rest             Object with other input attributes
 * @return {HTMLELement}
 */
const Label = ({
  htmlFor,
  isError = false,
  className,
  variant = "default",
  children,
  ...rest
}) => (
  <label
    className={cx(
      styles.label,
      {
        [styles.error]: isError,
        [styles.animated]: variant === "animated",
      },
      className
    )}
    htmlFor={htmlFor}
    {...rest}
  >
    {children}
  </label>
);

export default Label;
