import React, { lazy, useEffect, useState } from "react";

const loadIcon = name => lazy(() => import(`./${name}Icon`));

/**
 * Icon component
 * @type {Object}
 * @property {string} name Name of svg component.
 * @property {*} rest fill, width, height, className default fill=black, width=17, height=18
 * @returns
 */
const Icon = ({ name, ...rest }) => {
  // @deprecated - Please use the Icon component from @operation-diameter
  if (process.env.REACT_APP_HONEY_BADGER_ENV !== 'production') {
    console.warn(
      'Icon component from Shared is deprecated. Please use the Icon component from @operation-diameter'
    );
  }

  const [Svg, setSvg] = useState();

  useEffect(() => {
    setSvg(loadIcon(name))
  }, [name]);

  return Svg && <Svg style={{ flex: 'none' }} {...rest} />;
};

export default Icon;
