import React, { useState } from 'react';

import cx from 'classnames';

import styles from './ExpandableArea.module.css';

const Heading = React.lazy(() => import('../Heading'));
/**
 * 
 * @param {string} variant          [block, link]
 * @param {boolean} isOpen
 * @param {React.Element} title
 * @param {string} titleClassName
 * @param {string} headingClassName
 * @param {React.Element} children
 * @param {string} className
 * @returns 
 */
const ExpandableArea = ({
  variant = 'block',
  isOpen = false,
  headingClassName,
  titleClassName,
  title,
  children,
  className
}) => {
  const [showContent, setShowContent] = useState(isOpen);

  return (title && (
    <div className={cx(styles.expandableArea, className)} data-testid="expandableArea">
      <div className={cx(styles.heading, styles[variant], headingClassName)} onClick={() => setShowContent(!showContent)}>
        <Heading
          type={5}
          className={cx(styles.title, styles[variant], titleClassName)}
          title={title}
        />
        <i role="img" className={cx(styles.chevron, { [styles.open]: showContent })} />
      </div>
      {showContent && (
        <div className={styles.content}>
          {children}
        </div>
      )}
    </div>
  )) || null;
};

export default ExpandableArea;
