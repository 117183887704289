import React, { useCallback } from 'react';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';

import { isExternalLink } from '../../utils';
import useAnalytics from '../Analytics';
import Icon from '../Icon';

import styles from './NavItem.module.css';

const TRACKER_NAME = 'borrower-portal';
// Icon Name mapping
const ICON = {
  'call-made': 'callMade',
  history: 'history',
  'calendar-today': 'calendarToday',
  'credit-card': 'creditCard',
  'group-add': 'groupAdd',
  'call-received': 'callReceived',
  cashback: 'cashback',
  transactions: 'transactions',
  'view-agenda': 'viewAgenda'
};

/**
 * React router dom NavLink
 * @type {Object}
 * @property {string} to A location to redirect to, pathname can be any valid URL path that.
 * @property {string} label Indicates link label.
 * @property {*} children Can be used instead of label. label takes priority
 * @property {*} className can either be a string or a function that returns a string.
 * @property {function} onClick
 * @property {string} iconName  Icon name for the NavItem
 * @returns Return NavLink router dom element.
 */
const NavItem = React.memo(
  ({
    children,
    to,
    label,
    className,
    notification,
    onClick,
    disabled = false,
    iconName,
    ...rest
  }) => {
    const { sendToTracker } = useAnalytics();

    if (!to || (!label && !children)) {
      return null;
    }

    if (iconName && !ICON.hasOwnProperty(iconName)) {
      console.error(`${iconName} is not a valid icon name`);
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const handleTracking = useCallback(
      (evt) => {
        sendToTracker({
          name: TRACKER_NAME,
          event: 'event',
          data: {
            eventCategory: 'Navigate',
            eventAction: 'Navigate',
            eventLabel: evt.target.innerText,
            eventValue: 1
          }
        });

        // If onClick as been has been passed, run it
        onClick?.(evt);
      },
      [sendToTracker, onClick]
    );

    return (
      <NavLink
        to={to}
        className={({ isActive }) =>
          cx(styles.navItem, className, {
            [styles.notification]: notification,
            [styles.active]: !disabled && isActive,
            [styles.disabled]: disabled
          })
        }
        onClick={handleTracking}
        reloadDocument={isExternalLink(to)}
        {...rest}
      >
        {iconName && !disabled && ICON.hasOwnProperty(iconName) && (
          <Icon name={ICON[iconName]} />
        )}
        {label || children}
      </NavLink>
    );
  }
);

export default NavItem;
