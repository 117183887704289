import React from 'react';
import cx from 'classnames';

import Icon from '../Icon';
import Heading from '../Heading';
import Text from '../Text';

import styles from './PageError.module.css';

/**
 * PageError
 * @param {string} title             Page error title
 * @param {string} message           Info message for the page
 * @param {string} icon              Show icon svg
 * @param {string} iconClass         Icon className
 * @param {string} className         className custom class name to add to the PageError component
 * @param {*} children               Content
 */
const PageError = ({ title, message = "", icon, iconClass, className, children }) => (
  <div className={cx(styles.pageError, className)}>
    {icon && <Icon name={icon} className={cx(styles.icon, iconClass)} />}
    <Heading
      type="3"
      title={title}
      className={styles.header}
    />
    {message && <Text className={styles.infoMessage}>
      {message}
    </Text>}
    {children}
  </div>
);

export default PageError;
