import React from 'react';
import cx from 'classnames';

import styles from './CategoryChip.module.css';

/**
 * CategoryChip
 * @param {string} variant [filled*, outlined]
 * @param {string} size [small*, medium]
 * @param {string} type [info*, action, attention, success, error]
 * @param {string} className
 * @param {HTMLElement} children
 * @param {*} rest
 * @returns
 */
const CategoryChip = ({
  variant = 'filled',
  size = 'small',
  type = 'info',
  className,
  children,
  ...rest
}) => {
  // @deprecated - Please use the Pill component from @operation-diameter
  if (process.env.REACT_APP_HONEY_BADGER_ENV !== 'production') {
    console.warn(
      'CategoryChip component from Shared is deprecated. Please use the Pill component from @operation-diameter'
    );
  }

  return (
    (children && (
      <div
        className={cx(
          styles.chip,
          styles[variant],
          styles[size],
          styles[type],
          className
        )}
        {...rest}
      >
        {children}
      </div>
    )) ||
    null
  );
};

export default CategoryChip;
