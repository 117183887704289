import React from "react";
import cx from "classnames";

import styles from "./Detail.module.css";

const Detail = ({ label, value, className }) => (
  <div className={cx(styles.detail, className)}>
    <div className={styles.label}>{label}</div>
    <div className={styles.value}>{value}</div>
  </div>
);

export default Detail;
