import React from 'react';
import cx from 'classnames';

import Heading from '../Heading';
import Link from '../Link';
import Text from '../Text';
import AvatarImg from '../../assets/Avatar.webp';

import styles from './CustomerSupport.module.css';

/**
 * CustomerSupport component
 * @param {string} helpText
 * @param {string} phoneNumber
 * @param {email} email
 * @param {string} className
 * @returns
 */
const CustomerSupport = ({
  helpText,
  phoneNumber = '0800 048 2467',
  email = 'credit@fundingcircle.com',
  className
}) => (
  <div className={cx(styles.wrapper, className)}>
    {helpText &&
      <div className={styles.section}>
        <Text className={styles.text}>{helpText}</Text>
      </div>
    }
    <div className={styles.section}>
      <img src={AvatarImg} className={styles.avatar} alt="Avatar" />
      <div>
        <Heading type="2" title={phoneNumber} className={styles.name} />
        <Link to={`mailto:${email}`}>{email}</Link>
      </div>
    </div>
  </div>
);

export default CustomerSupport;
