import React, { useCallback, useMemo, useState } from "react";
import { useFormContext } from 'react-hook-form';

import styles from './TextArea.module.css';

const NoteAndError = React.lazy(() => import('../NoteAndError'));
const Text = React.lazy(() => import('../Text'));

const TextArea = ({
  label,
  name,
  rules,
  note,
  maxLength = 200,
  rows = 6,
  showCharacterCount = false,
  onChange,
  ...rest
}) => {
  // @deprecated - Please use the Multiline Text Input component from @operation-diameter
  if (process.env.REACT_APP_HONEY_BADGER_ENV !== 'production') {
    console.warn(
      'TextArea component from Shared is deprecated. Please use the Multiline Text Input component from @operation-diameter'
    );
  }

  const [count, setCount] = useState(0);
  const { register, getFieldState, formState } = useFormContext();
  const error = useMemo(
    () => getFieldState(name, formState).error,
    [name, formState, getFieldState]
  );

  const onChangeHandler = useCallback((event) => {
    setCount(event.target.value.length);
    onChange?.(event);
  },
    [setCount, onChange]
  );

  return (label && (
    <div className={styles.textarea} data-testid="textArea">
      {label && <label className={styles.label}>{label}</label>}
      <textarea
        type="text"
        className={styles.field}
        {...register(name, rules)}
        rows={rows}
        onChange={onChangeHandler}
        maxLength={maxLength}
        {...rest}
      />
      {showCharacterCount && <Text type="2">{maxLength - count} characters remaining</Text>}
      <NoteAndError note={note} error={error} />
    </div>
  )) || null;
};
export default TextArea;
