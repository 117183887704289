/* global ga */
/**
 * Some reading materials, regarding analytics
 * Main Analytics documentation:
 *   https://developers.google.com/analytics/devguides/collection/analyticsjs
 * About events tracking documentation:
 *   https://support.google.com/analytics/answer/1033068#Anatomy&zippy=%2Cin-this-article
 */

const ANALYTICS_URL = 'https://www.google-analytics.com/analytics.js';
// Event types and the fields to be sent when senfing an event hit
const EVENT_TYPES = {
  pageview: ['page', 'location', 'title'],
  event: ['eventCategory', 'eventAction', 'eventLabel', 'eventValue', 'nonInteraction'],
  timing: ['timingCategory', 'timingVar', 'timingValue', 'timingLabel', 'nonInteraction']
};

const useAnalytics = () => {
  /**
   * Function that will load the analytics scripts and initialize them
   * @return {Promise/void}
   */
  const init = () => new Promise((res, rej) => {
    try {
      // Create fragment ot be added into the document
      let fragment = new DocumentFragment();

      // Initialise script
      const initScript = document.createElement('script');
      initScript.innerHTML = 'window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;';
      // Append initialise script to the fragment
      fragment.append(initScript);

      // Load analytics library script
      const libScript = document.createElement('script');
      libScript.async = true;
      // libScript.defer = true;
      libScript.src = ANALYTICS_URL;
      // Append library script to fragment
      fragment.append(libScript);

      // Append fragment to document
      document.body.appendChild(fragment);

      res(ga);
    } catch (err) {
      rej(err);
    }
  });

  /**
   * Function to add a tracker into Google Ananlytics
   * @param {string} name       Name of the tracker to be registered on Google Analytics
   * @param {string} id         Id of the tracker to be registered on Google Analytics
   * @return {Promise/object}   Tracker object
   */
  const addTracker = ({
    name,
    id = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID
  }) => new Promise(async (res, rej) => {
    // Check if ga exists
    if (!window.hasOwnProperty('ga')) {
      await init();
    }

    // Check if a tracker with the same name exists
    if (typeof ga.getByName === 'function' && ga.getByName(name)) {
      rej(new Error(`A tracker with the name "${name}" already exists.`));
    }

    // Create new tracker
    ga('create', id, 'auto', name, { transport: 'beacon' });

    // Check if tracker was created successfully
    ga(async function () {
      const tracker = await ga.getByName(name);

      if (!tracker) {
        rej(new Error(`Error creating tracker with name ${name}.`));
      }

      // return tracker
      res(tracker);
    })
  });

  /**
   * Function to remove a tracker from Google Analytics
   * @param {string} name       Name of tracker to be removed
   * @return {Promise/boolean}  Promise the resolves a boolean if the tracker was removed
   */
  const removeTracker = name => new Promise((res, rej) => {
    if (!name) {
      rej(new Error('Please provide the name of the tracker you want to remove.'));
    }

    ga(function () {
      const tracker = ga.getByName(name);

      if (!tracker) {
        rej(new Error(`Tracker with name "${name}" does not exist.`));
      }

      ga.remove(name);
      res(true);
    });
  });

  /**
   * Function to return a previously added GA Tracker
   * @param {string} name       Name of the Google Analytics tracker to return
   * @return {Promise/object}   Google Analytics tracker object
   */
  const getTracker = name => new Promise(async (res, rej) => {
    if (!name) {
      rej(new Error('Please provide the name of the tracker you want to get.'));
    }

    // Check if ga exists
    if (!window.hasOwnProperty('ga')) {
      await init();
    }

    ga(async function () {
      const tracker = await ga.getByName(name);
      res(tracker || null);
    });
  });

  /**
   * Function to send an event hit from a tracker
   * @param {string} name   Name of the Google Analytics tracker to send the event to
   * @param {string} event  Event hit type. Options are pageview, event, timing
   * @param {object} data   Data object to send to the tracker
   * @return {Promise/void}
   */
  const sendToTracker = ({ name, event, data }) => new Promise((res, rej) => {
    if (!name) {
      rej(new Error('Please provide the name of the tracker you want to send an event to.'));
    }

    const tracker = window.ga?.getByName?.(name);

    if (!tracker) {
      console.warn(`Tracker with name "${name}" does not exist.`);
      return;
      // rej(new Error(`Tracker with name "${name}" does not exist.`));
    }

    if (!event || !EVENT_TYPES.hasOwnProperty(event)) {
      rej(new Error('Please provide a valid event type.'));
    }

    if (event === 'pageview') {
      pageviewSend({
        tracker, data: {
          title: document.title,
          location: window.location.href,
          page: window.location.pathname,
          ...data
        }
      });
    } else {
      eventSend({ tracker, event, data });
    }
  });

  /**
   * Function to send an event or timing events to Analytics
   * NOTE: Any other property than the ones defined will not be sent
   * @param {object} tracker  Google Analytics Tracker object
   * @param {string} event    Event hit type
   * @param {object} data     Data object to send with event
   * @return void
   */
  const eventSend = ({ tracker, event, data }) => {
    tracker.send(
      event,
      EVENT_TYPES[event].reduce(
        (acc, key) => ({
          ...acc,
          // Check if data object contains property and, only if it does, add it
          ...((data.hasOwnProperty(key) && { [key]: data[key] }) || {})
        }),
        {}
      )
    );
  }

  /**
   * Function to send a pageview event to Analytics
   * @param {object} tracker    Google Analytics Tracker object
   * @param {object} data       Data object to send with event
   * @return void
   */
  const pageviewSend = ({ tracker, data }) => {
    // TODO: Add logic to replace te loan id on the URL

    Object.entries(data).forEach(([key, value]) => {
      tracker.set(key, value);
    });

    tracker.send('pageview');
  }

  return {
    addTracker,
    removeTracker,
    getTracker,
    sendToTracker
  };
};

export default useAnalytics;
