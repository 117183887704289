import React from 'react';
import cx from 'classnames';

import Loading from '../Loading';

import useAnalytics from '../Analytics';

import styles from './Button.module.css';

const TRACKER_NAME = 'borrower-portal';
const EVENT_TYPES = {
  event: ['eventCategory', 'eventAction', 'eventLabel', 'eventValue', 'nonInteraction'],
};

/**
 * @param {string} label            Indicates label for Button
 * @param {string} variant          Button variant - default primary [primary, secondary, link, icon]
 * @param {string} type             Button type - default button [button, submit]
 * @param {string} size             Button size - default large [small, medium, large]
 * @param {boolean} fullWidth       Indicates whether the Button is expanding to cover the parent full width
 * @param {*} iconPosition          Position of Button - default false [false, left, right]
 * @param {boolean} loading         Indicates whether the Button is loading or not
 * @param {string} loadingLabel     Indicates loading text when Button is loading
 * @param {boolean} showSpinner     Indicates whether a loading component will be rendered when loading
 * @param {boolean} disabled        Indicates if the Button is disabled or not
 * @param {string} className        Custom class properties
 * @param {*} onClick               Indicates click functionality for Button(Event Attribute)
 * @param {object} trackerObject    Custom tracker data to be sent to Google Analytics
 * @param {*} children              Button content
 * @returns                         Returns Button component
 */
const Button = ({
  label,
  variant = 'primary',
  type = 'button',
  size = 'large',
  fullWidth = false,
  iconPosition = false,
  loading = false,
  loadingLabel,
  showSpinner = false,
  disabled,
  className,
  onClick,
  trackerObject,
  children,
  ...rest
}) => {
  // @deprecated - Please use the Button component from @operation-diameter
  if (process.env.REACT_APP_HONEY_BADGER_ENV !== 'production') {
    console.warn(
      'Button component from Shared is deprecated. Please use the Button component from @operation-diameter'
    );
  }

  const { sendToTracker } = useAnalytics();

  const handleTracking = async (event) => {
    let eventData = {
      eventCategory: 'Action',
      eventAction: 'Action',
      eventLabel: label,
      eventValue: 1
    };

    if (trackerObject) {
      eventData = EVENT_TYPES.event.reduce(
        (acc, key) => ({
          ...acc,
          // Check if trackerObject contains only valid properties
          ...((trackerObject.hasOwnProperty(key) && { [key]: trackerObject[key] }) || {})
        }), {})
    }

    sendToTracker({
      name: TRACKER_NAME, event: 'event', data: eventData
    });

    if (onClick && typeof onClick === 'function') {
      onClick(event);
    }
  }

  return (
    <button
      type={type}
      className={cx(styles.button, className, [styles[variant]], [styles[size]], {
        [styles[`icon-${iconPosition}`]]: iconPosition,
        [styles.full]: fullWidth,
      })}
      disabled={disabled || loading}
      onClick={handleTracking}
      {...rest}
    >
      {(loading && loadingLabel) || label || children}
      {loading && showSpinner && <Loading type="button" className={styles.loading}/>}
    </button>
  );
}

export default Button;
