import React, { useState, useEffect, useCallback } from 'react';
import cx from 'classnames';

import Input from '../Input';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';

import styles from './SearchInput.module.css';

const SearchInput = ({
  options,
  label,
  type,
  initial,
  note,
  name,
  className,
  searchBy,
  disableFiltering,
  getOptionValue,
  getOptionLabel,
  renderOption,
  onOptionSelect,
  variant,
  children,
  onBlur,
  onChange,
  ctrlOnChange,
  value,
  loading,
  notFound,
  disabled,
  disableBlur,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);
  const [optionLabel, setOptionLabel] = useState(
    initial ? getOptionLabel(initial) : ''
  );
  const memoizedSearchBy = useCallback(
    (option) => searchBy?.(option) ?? [option],
    [searchBy]
  );

  useEffect(() => {
    if (disableFiltering) return setFilteredOptions(options);
    setFilteredOptions(
      options.filter((option) =>
        memoizedSearchBy(option).some(
          (value) =>
            value?.toLowerCase?.().indexOf(optionLabel.toLowerCase()) > -1
        )
      )
    );
  }, [memoizedSearchBy, optionLabel, options, disableFiltering]);

  const handleKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowDown':
        if (!isOpen) {
          setIsOpen(true);
        }
        setSelectedOptionIndex((prevIndex) =>
          prevIndex < filteredOptions?.length - 1 ? prevIndex + 1 : 0
        );
        break;
      case 'ArrowUp':
        if (!isOpen) {
          setIsOpen(true);
        }
        setSelectedOptionIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : filteredOptions?.length - 1
        );
        break;
      case 'Enter':
        e.preventDefault();
        if (selectedOptionIndex !== -1) {
          const option = filteredOptions[selectedOptionIndex];
          ctrlOnChange(getOptionValue(option));
          setOptionLabel(getOptionLabel(option));
          onOptionSelect(option);
        }
        setIsOpen(false);
        break;
      case 'Escape':
        setIsOpen(false);
        break;
      default:
        break;
    }
  };

  const handleChange = (e) => {
    const {
      target: { value }
    } = e;

    setSelectedOptionIndex(-1);
    setOptionLabel(value);
    ctrlOnChange('');
    onChange(value);
  };

  const handleBlur = (e) => {
    if (disableBlur) return e.target.focus();
    setIsOpen(false);
    onBlur(e);
  };

  const handleOptionSelect = (option, index) => {
    ctrlOnChange(getOptionValue(option));
    setSelectedOptionIndex(index);
    setOptionLabel(getOptionLabel(option));
    onOptionSelect(option);
  };

  const handleClear = () => {
    if (disabled) {
      return;
    }
    ctrlOnChange('');
    setOptionLabel('');
    onChange('');
    setSelectedOptionIndex(-1);
  };

  return (
    <div
      className={cx(styles.wrapper, styles[variant], className)}
      data-testid='search-input-wrapper'
    >
      <Input
        type={type}
        name={name}
        label={label}
        note={note}
        variant={variant}
        className={styles.field}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        onFocus={() => setIsOpen(true)}
        value={optionLabel}
        noRegister={true}
        disabled={disabled}
        {...rest}
      >
        {!optionLabel ? (
          <SearchIcon className={styles.searchIcon} />
        ) : (
          <div onClick={handleClear} className={styles.clearIcon}>
            &times;
          </div>
        )}
        {children}
      </Input>
      {isOpen && (
        <ul className={styles.menu}>
          {loading && <li data-testid='loading'>{loading}</li>}
          {notFound && <li data-testid='not-found'>{notFound}</li>}
          {!loading &&
            !notFound &&
            filteredOptions.length > 0 &&
            filteredOptions.map((option, index) => {
              return (
                <li
                  key={`${getOptionValue(option)}${index}`}
                  onMouseDown={() => handleOptionSelect(option, index)}
                  onTouchStart={() => handleOptionSelect(option, index)}
                >
                  {renderOption(option, index === selectedOptionIndex)}
                </li>
              );
            })}
        </ul>
      )}
    </div>
  );
};

export default SearchInput;
