import React, { lazy, useEffect, useState } from "react";

const loadLogo = name => lazy(() => import(`./${name}Logo`));

/**
 * Logo component
 * @type {Object}
 * @property {string} name Name of svg component.
 * @property {string} url Url for link wrapping the svg component.
 * @returns
 */

const Logo = ({ name }) => {
  const [Svg, setSvg] = useState();

  useEffect(() => {
    setSvg(loadLogo(name))
  }, [name]);

  return Svg && <Svg data-testid={`${name}-logo`}/>
};

export default Logo;
