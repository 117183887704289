import React from 'react';
import cx from 'classnames';

import styles from './Loading.module.css';

const Loading = ({
  label,
  type = 'core',
  position,
  className
}) => (
  <div className={cx(styles.loading, styles[position], styles[type])} data-testid="loading">
    {label && <span className={styles.label} data-testid="loading-label">{label}</span>}
    <span className={cx(styles.spinner, className)} />
  </div>
);

export default Loading;
