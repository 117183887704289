import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import SearchInput from './SearchInput';

/**
 * @param {Array.<string>|Array.<Object>} options   List of options
 * @param {HTMLELement} label                       Input field's label
 * @param {String} name                             HTML input field name
 * @param {String} type                             HTML input field types - default text
 * @param {string|Object} initial                   Field's initial state (optional)
 * @param {string} note                             Field's note (optional)
 * @param {Object} rules                            Field's rules object { key: value }. key is the rule name and value can be the error string or an object with value and message
 * @param {String} className                        Extra class name to add to the input field (optional)
 * @param {function} searchBy                       Extra class name to add to the input field (optional)
 * @param {function} getOptionValue                 Function to get option's value, this will be passed to the form state (optional)
 * @param {function} getOptionLabel                 Function to get option's label, this value will be displayed on select (optional)
 * @param {function} renderOption                   Function to render the option (optional)
 * @param {function} onChange                       Function to be called on change (optional)
 * @param {function} onOptionSelect                 Function to be called on select (optional)
 * @param {HTMLELement} loading                     Text to be displayed when a search is in progress (optional)
 * @param {HTMLELement} notFound                    Text to be displayed when a search found no results (optional)
 * @param {String} variant                          Input variant [default, animated] *default
 * @param {boolean} disabled                        Disabled attribute *false
 * @param {boolean} disableBlur                     Disable blur event *false
 * @param {boolean} disableFiltering                Disable filtering options *false
 * @param {HTMLELement} children                    HTMLElements to complement the input field (optional)
 * @param {Object} rest                             Object with other input attributes (optional)
 * @return {HTMLELement}
 */

function InputController({
  options = [],
  label,
  name,
  type,
  initial = '',
  note,
  rules,
  className,
  searchBy,
  getOptionValue = (option) => option,
  getOptionLabel = (option) => option,
  renderOption = (option, isSelected) => option,
  onChange = (value) => value,
  onOptionSelect = (option) => option,
  loading,
  notFound,
  variant = 'default',
  disabled = false,
  disableBlur = false,
  disableFiltering = false,
  children,
  ...rest
}) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={getOptionValue(initial)}
      rules={rules}
      render={({
        field: { ref, onChange: controllerOnChange, ...otherControllerProps }
      }) => (
        <SearchInput
          options={options}
          label={label}
          type={type}
          initial={initial}
          note={note}
          className={className}
          searchBy={searchBy}
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          onChange={onChange}
          ctrlOnChange={controllerOnChange}
          onOptionSelect={onOptionSelect}
          loading={loading}
          notFound={notFound}
          variant={variant}
          disabled={disabled}
          disableFiltering={disableFiltering}
          disableBlur={disableBlur}
          {...otherControllerProps}
          {...rest}
        >
          {children}
        </SearchInput>
      )}
    />
  );
}

export default InputController;
