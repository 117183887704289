import React, { useMemo } from 'react';
import cx from 'classnames';
import { useFormContext } from 'react-hook-form';

import NoteAndError from '../NoteAndError';

import styles from './Checkbox.module.css';
/**
 * Checkbox component
 * @param {String} name               Name for the field
 * @param {String} label              Label for the field
 * @param {Boolean} value             Value for the checked field for checkbox
 * @param {String} note               Field's note 
 * @param {*} onClick                 Indicates click functionality
 * @param {String} className          A class name to overwrite the default styles
 * @param {Object} rules              An object with rules for the field
 * @param {Object} rest               Object with other checkbox attributes
 * @returns 
 */
const Checkbox = ({ 
  name,
  label,
  value = false,
  note,
  onClick,
  className,
  rules,
  ...rest
}) => {
  const { register, getFieldState, formState } = useFormContext();
  const error = useMemo(
    () => getFieldState(name, formState).error,
    [name, formState, getFieldState]
  );

  return (name && (
    <div className={cx(styles.checkbox, className)}>
      <div className={styles.wrapper}>
        <input
          id={name}
          type="checkbox"
          checked={value}
          className={styles.field}
          onClick={onClick}
          {...register(name, rules)}
          {...rest}
        />
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
      </div>
      <NoteAndError note={note} error={error} />
    </div>
  )) || null;
};

export default Checkbox;
