import React from "react";

import Heading from '../../Heading';
import Link from '../../Link';

import styles from './SiteLinks.module.css';

const LEGAL_LINKS = [
  {
    title: 'Legal Documents',
    children: [
      {
        url: 'https://www.fundingcircle.com/uk/legal/',
        label: 'Legal'
      },
      {
        url: 'https://www.fundingcircle.com/home/security',
        label: 'Security'
      },
      {
        url: 'https://static.fundingcircle.com/files/uk/information-packs/modern-slavery-act-statement-760f0c13a9.pdf',
        label: 'Modern Slavery Statement'
      }
    ]
  }
];

const SiteLinks = ({linksData}) => (
  <div className={styles.wrapper}>
    {linksData.map(({ title, children }) => (
      <section className={styles.linkSection} key={title}>
        <Heading type='6' title={title} className={styles.heading} />
        {children && children.map(({ label, url }) => (
          <Link
            to={url}
            target={/^(tel:)|(mailto:)/.test(url) ? '_self' : '_blank'}
            className={styles.siteUrl}
            key={label}>
            {label}
          </Link>
        ))}
      </section>
    ))}
  </div>
);

export { SiteLinks, LEGAL_LINKS };
