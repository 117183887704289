import React from 'react';
import cx from 'classnames';

import styles from './Text.module.css';

/**
 * @param {*} type Type of paragraph tag {p1, p2, p3} default is 1 [1, 2, 3, <string>].
 * @param {string} className Custom class for paragraph tag.
 * @param {*} children Child component to be enclosed inside the paragraph tag.
 * @returns Returns a paragraph tag and groups child component.
 */
const Text = ({ type = 1, className = '', children }) => {
  // @deprecated - Please use the Paragraph component from @operation-diameter
  if (process.env.REACT_APP_HONEY_BADGER_ENV !== 'production') {
    console.warn(
      'Text component from Shared is deprecated. Please use the Paragraph component from @operation-diameter'
    );
  }

  return (
    (children && (
      <p
        className={cx(
          styles.paragraph,
          styles[(isNaN(type) && type) || `p${type}`],
          className
        )}
      >
        {children}
      </p>
    )) ||
    null
  );
};

export default Text;
