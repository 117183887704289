import React, { useState } from 'react';
import cx from 'classnames';

import Button from './components/Button';
import Detail from './components/Detail';
import Error from './components/Error';
import Link from './components/Link';
import Loading from './components/Loading';
import SectionHeader from './components/SectionHeader';
import Heading from './components/Heading';
import Text from './components/Text';
import InfoArea from './components/InfoArea';
import Icon from './components/Icon';
import Select from './components/Select';
import ToolTip from './components/ToolTip';
import CustomerSupport from './components/CustomerSupport';
import Notice from './components/Notice';
import Modal from './components/Modal';
import Form from './components/Form';
import RadioGroup from './components/RadioGroup';
import CurrencyInput from './components/CurrencyInput';
import Trustpilot from './components/Trustpilot';
import TextArea from './components/TextArea';
import ExpandableArea from './components/ExpandableArea';
import Header from './components/Header';
import Logo from './components/Logo';
import NavGroup from './components/NavGroup';
import NavItem from './components/NavItem';
import CategoryChip from './components/CategoryChip';
import Checkbox from './components/Checkbox';
import SearchInputExample from './SearchInputExample';
import { LEGAL_LINKS, SiteLinks } from './components/Footer/SiteLinks';
import Input from './components/Input';
import NoteAndError from './components/NoteAndError';
import Table from './components/Table';

import styles from './App.module.css';

const App = () => {
  const [modalState, setModalState] = useState(false);
  const [showNotice, setShowNotice] = useState(true);
  const [checkbox, setCheckbox] = useState(false);

  const items = [
    { col1: 'Column 1 / Row 1', col2: 'Column 2 / Row 1' },
    { col1: 'Column 1 / Row 2', col2: 'Column 2 / Row 2' },
    { col1: 'Column 1 / Row 3', col2: 'Column 2 / Row 3' }
  ];

  const itemComponent = ({ col1, col2, className }) => (
    <div className={`item ${className}`} style={{ gap: '1rem' }}>
      <div className='cell' style={{ flex: '1 1' }}>
        {col1}
      </div>
      <div className='cell' style={{ flex: '1 1' }}>
        {col2}
      </div>
    </div>
  );

  return (
    <div className={styles.app}>
      <div className={styles.deprecationBanner}>
        <p>
          <strong>
            ❗Some components here are being deprecated. Please use components
            from the @operation-diameter package.❗
          </strong>
        </p>
      </div>
      <h2>Buttons (Deprecated)</h2>
      <div className={styles.demos}>
        <label>Primary button</label>
        <Button label='Primary button' />
        {/*<label>Primary button with icon</label>*/}
        {/*<Button label="Primary button with icon" iconPosition="left" />*/}
        <label>Disabled primary button</label>
        <Button label='Disabled primary button' disabled />
        <label>Secondary button</label>
        <Button variant='secondary' label='Secondary button' />
        {/*<label>Secondary button with icon</label>*/}
        <label>Link button</label>
        <Button variant='link' label='Link button' />
        {/*<label>Link with icon</label>*/}
      </div>
      <h2>Links (Deprecated)</h2>
      <div className={styles.demos}>
        <label>Normal link</label>
        <Link to='/'>Normal link</Link>
        <label>Link with icon</label>
        <Link to='/' back showIcon>
          Link with icon
        </Link>
        <label>Link with back icon</label>
        <Link to='/' back showIcon>
          Link with back icon
        </Link>
      </div>
      <h2>Loading</h2>
      <div className={styles.demos}>
        <label>Loading core</label>
        <Loading label='Loading...'></Loading>
      </div>
      <h2>Section header</h2>
      <div className={styles.demos}>
        <label>Section header</label>
        <SectionHeader className={styles.section}>
          <Heading type='2' alt title='Make a payment' />
        </SectionHeader>
      </div>
      <h2>Heading (Deprecated)</h2>
      <div className={styles.demos}>
        <label>H1 heading</label>
        <Heading type='1' title='Heading 1' />
        <label>H1 heading with left accent</label>
        <Heading type='1' title='Heading 1' accent='left' />
        <label>H1 heading with center accent</label>
        <Heading type='1' title='Heading 1' accent='center' />
        <label>H1 heading alt</label>
        <Heading type='1' title='Heading 1' alt />
        <label>H1 heading alt with left accent</label>
        <Heading type='1' title='Heading 1' accent='left' alt />
        <label>H1 heading alt with center accent</label>
        <Heading type='1' title='Heading 1' accent='center' alt />
        <label>H2 heading</label>
        <Heading type='2' title='Heading 2' />
        <label>H2 heading with left accent</label>
        <Heading type='2' title='Heading 2' accent='left' />
        <label>H2 heading with center accent</label>
        <Heading type='2' title='Heading 2' accent='center' />
        <label>H2 heading alt</label>
        <Heading type='2' title='Heading 2' alt />
        <label>H2 heading alt with left accent</label>
        <Heading type='2' title='Heading 2' accent='left' alt />
        <label>H2 heading alt with center accent</label>
        <Heading type='2' title='Heading 2' accent='center' alt />
        <label>H3 heading</label>
        <Heading type='3' title='Heading 3' />
        <label>H3 heading does not have a accent</label>
        <Heading type='3' title='Heading 3' alt />
        <label>H4 heading</label>
        <Heading type='4' title='Heading 4' />
        <label>H5 heading</label>
        <Heading type='5' title='Heading 5' />
      </div>
      <h2>Text (Deprecated)</h2>
      <div className={styles.demos}>
        <label>Text paragraph 1</label>
        <Text type='1'>This is a paragraph 1 content</Text>
        <label>Text paragraph 2</label>
        <Text type='2'>This is a paragraph 2 content</Text>
        <label>Text paragraph 3</label>
        <Text type='3'>This is a paragraph 3 content</Text>
        <label>Text label</label>
        <Text type='label'>This is a label content</Text>
      </div>
      <h2>Detail</h2>
      <div className={styles.demos}>
        <label>Detail Default</label>
        <Detail label='A detail label' value='A detail value' />
      </div>
      <h2>Info Area</h2>
      <div className={styles.demos}>
        <label>InfoArea</label>
        <InfoArea>
          <Text>
            This is the content of the info{' '}
            <a
              href='https://www.fundingcircle.com'
              rel='noreferrer'
              target='_blank'
            >
              area
            </a>
            . It can take any kind of content.
          </Text>
        </InfoArea>
      </div>
      <h2>Icon (Deprecated)</h2>
      <div className={styles.demos}>
        <label>Info</label>
        <Icon name='info' fill='red' />
      </div>
      <h2>Notice (Deprecated)</h2>
      <div className={styles.noticeWrapper}>
        <label>Compact</label>
        <Notice>notice</Notice>
        <Notice type='action'> notice </Notice>
        <Notice type='attention'> notice </Notice>
        <Notice type='success'> notice </Notice>
        <Notice type='error'> notice </Notice>
        <label>Compact with title</label>
        <Notice title='notice title'>notice content</Notice>
        <Notice title='notice title' type='action'>
          {' '}
          notice content
        </Notice>
        <Notice title='notice title' type='attention'>
          {' '}
          notice content{' '}
        </Notice>
        <Notice title='notice title' type='success'>
          {' '}
          notice content{' '}
        </Notice>
        <Notice title='notice title' type='error'>
          {' '}
          notice content{' '}
        </Notice>
        <label>Small</label>
        <div className={styles.noticeSmallContent}>
          <Notice>notice</Notice>
          <Notice type='action'> notice </Notice>
          <Notice type='attention'> notice </Notice>
          <Notice type='success'> notice </Notice>
          <Notice type='error'> notice </Notice>
        </div>
        <label>Small with title and icon</label>
        <div className={styles.noticeSmallContent}>
          <Notice title='notice title' showIcon>
            notice
          </Notice>
          <Notice title='notice title' type='action' showIcon>
            notice
          </Notice>
          <Notice title='notice title' type='attention' showIcon>
            notice
          </Notice>
          {showNotice && (
            <Notice
              title='notice title'
              showIcon
              type='success'
              showClose
              onCloseClick={() => setShowNotice(!showNotice)}
            >
              notice
            </Notice>
          )}
          <Notice title='notice title' type='error' showIcon>
            notice
          </Notice>
        </div>
        <label> &lt;components&gt;</label>
        <div className={styles.noticeSmallContent}>
          <Notice title='Text component'>
            <Text type='1'>This is a paragraph 1 content</Text>
          </Notice>
          <Notice title='Heading component'>
            <Heading type='3' title='Heading 3' />
          </Notice>
        </div>
        <label>Custom Icons</label>
        <div className={styles.noticeWrapper}>
          <Notice title='Notice title' showIcon iconType='success'>
            Notice with custom icon and type default
          </Notice>
          <Notice title='Notice title' type='error' showIcon iconType='default'>
            Notice with custom icon and type error
          </Notice>
        </div>
        <label>Custom Icon styling</label>
        <div className={styles.noticeWrapper}>
          <Notice
            title='Notice title'
            showIcon
            iconType='success'
            iconClassName={styles.noticeIcon}
          >
            Notice with custom icon and custom icon styling
          </Notice>
        </div>
      </div>
      <h2>Tool Tip</h2>
      <div className={styles.demos}>
        <label>Info</label>
        <div className={styles.demoFlex}>
          Tooltip with Icon &lt;component&gt; as child
          <ToolTip content='Tooltip information'>
            <Icon name='info' />
          </ToolTip>
        </div>
        <label>
          Primary tooltip with Text &lt;component&gt; as child default position
          bottom
        </label>
        <ToolTip content='Tooltip information'>
          <Text type='label'>
            This is a child content with default position bottom
          </Text>
        </ToolTip>
        <label>
          Primary tooltip with Text &lt;component&gt; as child default position
          top
        </label>
        <ToolTip content='Tooltip information' position='top'>
          <Text type='label'>
            This is a child content with default position top
          </Text>
        </ToolTip>
        <label>
          Primary tooltip with Text &lt;component&gt; as child default position
          right
        </label>
        <ToolTip content='Tooltip information' position='right'>
          <Text type='label'>
            This is a child content with default position right
          </Text>
        </ToolTip>
        <label>
          Primary tooltip with Text &lt;component&gt; as child default position
          right
        </label>
        <ToolTip
          content='Tooltip information goes here and will typically be specified in mockup form or as part of a component.
It is often used to specify extra information about something when the user moves t'
          position='right'
        >
          <Text type='label'>
            This is a child content with default position right
          </Text>
        </ToolTip>
        <label>
          Primary tooltip with Text &lt;component&gt; as child default position
          left
        </label>
        <ToolTip content='Tooltip information' position='left'>
          <Text type='label'>
            This is a child content with default position left.
          </Text>
        </ToolTip>
      </div>
      <h2>CustomerSupport</h2>
      <div className={styles.demos} style={{ alignItems: 'unset' }}>
        <label>CustomerSupport without the helper text</label>
        <CustomerSupport />
        <label>CustomerSupport with help text</label>
        <CustomerSupport helpText='Need help with your application? Your account manager can help guide you through the process to get your decision.' />
        <label>CustomerSupport with passed phone number and email</label>
        <CustomerSupport
          phoneNumber='+44 800 048 2467'
          email='different.email@fundingcircle.com'
        />
      </div>
      <h2>Select (Deprecated)</h2>
      <div className={styles.demos}>
        <Form>
          <Select
            name={'gender'}
            label='Select gender'
            options={[
              { value: 1, label: 'Option 1' },
              { value: 2, label: 'Option 2' },
              { value: 3, label: 'Option 3', disabled: true }
            ]}
            rules={{
              required: 'Please select an option'
            }}
          />
        </Form>
      </div>
      <h2>Radio group (Deprecated)</h2>
      <div className={styles.demos}>
        <Form>
          <RadioGroup
            label='Radio group label'
            name='radio'
            items={[
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ]}
            rules={{
              required: 'Field is required'
            }}
          />
        </Form>
      </div>
      <h2>CurrencyInput (Deprecated)</h2>
      <div className={styles.demos}>
        <Form>
          <CurrencyInput name='currency' />
        </Form>
      </div>
      <h2>Modal</h2>
      <div className={styles.demos}>
        <Button label='Open modal' onClick={() => setModalState(true)} />
        {modalState && (
          <Modal
            closeIcon
            onClick={() => setModalState(false)}
            title='Modal title'
            description='Modal description'
          >
            <Text>This is the modal content</Text>
            <Text>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
              in ex nulla. Duis sit amet eros quis turpis sollicitudin consequat
              eget eget metus. Aliquam neque massa, placerat vel ligula sit
              amet, porta mattis metus. Etiam sit amet nunc condimentum, tempus
              lacus malesuada, pellentesque nibh. Nam tincidunt tincidunt nisi
              nec porttitor. Ut vitae feugiat lorem. Nunc lorem nibh, placerat
              eget sapien nec, maximus vulputate tellus. Phasellus ac ligula
              tellus.
            </Text>
            <Text>
              Maecenas turpis justo, dapibus ac lectus eu, feugiat rhoncus orci.
              Maecenas sem metus, pellentesque eu tellus tempus, dignissim
              posuere ligula. Orci varius natoque penatibus et magnis dis
              parturient montes, nascetur ridiculus mus. Pellentesque
              sollicitudin purus id mauris pretium tincidunt. Mauris non
              accumsan magna, ut ultricies lectus. Mauris aliquet turpis in
              aliquet efficitur. Vivamus ut nulla maximus, tincidunt tortor a,
              cursus nisl. Integer ut eros et massa egestas volutpat.
            </Text>
          </Modal>
        )}
      </div>
      <h2>Trustpilot (Deprecated)</h2>
      <div className={cx(styles.demos, styles.trustpilot)}>
        <Trustpilot />
      </div>
      <h2>TextArea (Deprecated)</h2>
      <div className={styles.demos}>
        <Form>
          <TextArea
            label='TextArea label'
            name='name'
            showCharacterCount
            maxLength={5}
          />
        </Form>
      </div>
      <h2>ExpandableArea</h2>
      <label>Default Block variant</label>
      <ExpandableArea title='Lorem ipsum dolor sit amet consectur yara yara yara'>
        <Text>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in ex
          nulla. Duis sit amet eros quis turpis sollicitudin consequat eget eget
          metus. Aliquam neque massa, placerat vel ligula sit amet, porta mattis
          metus. Etiam sit amet nunc condimentum, tempus lacus malesuada,
          pellentesque nibh. Nam tincidunt tincidunt nisi nec porttitor. Ut
          vitae feugiat lorem. Nunc lorem nibh, placerat eget sapien nec,
          maximus vulputate tellus. Phasellus ac ligula tellus.
        </Text>
      </ExpandableArea>
      <label>Block variant with custom title</label>
      <ExpandableArea
        title={
          <>
            <Icon name='info' />
            <Text type={1}>
              Lorem ipsum dolor sit amet consectur yara yara yara
            </Text>
          </>
        }
      >
        <Text>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in ex
          nulla. Duis sit amet eros quis turpis sollicitudin consequat eget eget
          metus. Aliquam neque massa, placerat vel ligula sit amet, porta mattis
          metus. Etiam sit amet nunc condimentum, tempus lacus malesuada,
          pellentesque nibh. Nam tincidunt tincidunt nisi nec porttitor. Ut
          vitae feugiat lorem. Nunc lorem nibh, placerat eget sapien nec,
          maximus vulputate tellus. Phasellus ac ligula tellus.
        </Text>
      </ExpandableArea>
      <label>Link variant</label>
      <ExpandableArea
        title='Lorem ipsum dolor sit amet consectur yara yara yara'
        variant='link'
      >
        <Text>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in ex
          nulla. Duis sit amet eros quis turpis sollicitudin consequat eget eget
          metus. Aliquam neque massa, placerat vel ligula sit amet, porta mattis
          metus. Etiam sit amet nunc condimentum, tempus lacus malesuada,
          pellentesque nibh. Nam tincidunt tincidunt nisi nec porttitor. Ut
          vitae feugiat lorem. Nunc lorem nibh, placerat eget sapien nec,
          maximus vulputate tellus. Phasellus ac ligula tellus.
        </Text>
      </ExpandableArea>
      <h2>Header</h2>
      <label>Header</label>
      <Header>
        <Link to='https://www.fundingcircle.com' target='_blank'>
          <Logo name='black' />
        </Link>
      </Header>
      <h2>NavGroup</h2>
      <div className={styles.navigation}>
        <NavItem to='/' label='Summary' onClick={() => console.log('Click')} />
        <NavGroup header='Header'>
          <NavItem
            to='/overview'
            label='Overview'
            onClick={() => console.log('Click')}
          />
          <NavItem
            to='/loans'
            label='Loans'
            onClick={() => console.log('Click')}
            disabled
          />
          <NavItem
            to='/other-stuff'
            label='Other stuff'
            onClick={() => console.log('Click')}
          />
        </NavGroup>
        <NavGroup header='Header 2'>
          <NavItem
            to='/accounts'
            label={
              <>
                <Icon name='info' />
                <Text type={1} className={styles.navItemText}>
                  Accounts
                </Text>
              </>
            }
            onClick={() => console.log('Click')}
          />
        </NavGroup>
        <NavItem
          to='/support'
          label='Support'
          onClick={() => console.log('Click')}
        />
        <NavItem
          to='/signout'
          label='Sign out'
          onClick={() => console.log('Click')}
        />
      </div>
      <h2>CategoryChip (Deprecated)</h2>
      <div className={styles.chip}>
        <CategoryChip>Default filled chip</CategoryChip>
        <br />
        <CategoryChip variant='outline'>Outline chip</CategoryChip>
        <br />
        <CategoryChip size='medium'>Default chip with size medium</CategoryChip>
        <br />
        <CategoryChip variant='outline' size='medium'>
          Outline chip with size medium
        </CategoryChip>
        <br />
        <CategoryChip type='action'>Filled chip with type action</CategoryChip>
        <br />
        <CategoryChip type='attention'>
          Filled chip with type attention
        </CategoryChip>
        <br />
        <CategoryChip type='success'>
          Filled chip with type success
        </CategoryChip>
        <br />
        <CategoryChip type='error'>Filled chip with type error</CategoryChip>
        <br />
        <CategoryChip variant='outline' type='action'>
          Outline chip with type action
        </CategoryChip>
        <br />
        <CategoryChip variant='outline' type='attention'>
          Outline chip with type attention
        </CategoryChip>
        <br />
        <CategoryChip variant='outline' type='success'>
          Outline chip with type success
        </CategoryChip>
        <br />
        <CategoryChip variant='outline' type='error'>
          Outline chip with type error
        </CategoryChip>
        <br />
        <CategoryChip type='error'>
          <Icon name='info' fill='white' />
          Custom children
        </CategoryChip>
        <br />
        <CategoryChip variant='outline' type='error'>
          <Icon name='info' fill='red' />
          Custom children
        </CategoryChip>
      </div>
      <h2>SearchInput</h2>
      <SearchInputExample />
      <h2>Checkbox</h2>
      <div className={styles.demos}>
        <Form>
          <label>Checkbox with position center</label>
          <Checkbox
            label='Checkbox name'
            name='default'
            value={checkbox}
            onClick={() => setCheckbox(!checkbox)}
          />
          <Checkbox
            label='Excepteur consequat duis consequat veniam fugiat magna fugiat consequat. Cupidatat laborum do id occaecat eiusmod Lorem commodo officia proident enim sit Lorem non. Tempor anim duis officia qui.'
            name='text'
            value={checkbox}
            onClick={() => setCheckbox(!checkbox)}
          />
          <label>Checkbox with position top</label>
          <Checkbox
            label='Et esse laborum irure voluptate est. Nulla esse cupidatat ipsum cillum anim. Laborum est Lorem commodo sunt in ullamco id nulla. In aliquip dolor aliqua aliquip consequat ex quis. Occaecat minim enim culpa tempor duis ullamco excepteur velit duis mollit pariatur sunt.Tempor dolor cupidatat fugiat exercitation ad ad id sunt aliqua ullamco eiusmod excepteur non. Non veniam qui nostrud aliquip occaecat tempor minim ut fugiat sint veniam. Irure ipsum consequat eu commodo sit pariatur ex tempor veniam.'
            name='top'
            value={checkbox}
            onClick={() => setCheckbox(!checkbox)}
            className={styles.checkboxStart}
          />
          <label>Checkbox with position bottom</label>
          <Checkbox
            label='Et esse laborum irure voluptate est. Nulla esse cupidatat ipsum cillum anim. Laborum est Lorem commodo sunt in ullamco id nulla. In aliquip dolor aliqua aliquip consequat ex quis. Occaecat minim enim culpa tempor duis ullamco excepteur velit duis mollit pariatur sunt.Tempor dolor cupidatat fugiat exercitation ad ad id sunt aliqua ullamco eiusmod excepteur non. Non veniam qui nostrud aliquip occaecat tempor minim ut fugiat sint veniam. Irure ipsum consequat eu commodo sit pariatur ex tempor veniam.'
            name='bottom'
            value={checkbox}
            onClick={() => setCheckbox(!checkbox)}
            className={styles.checkboxBottom}
          />
        </Form>
      </div>
      <h2>Nav Icons</h2>
      <div className={styles.navigation}>
        <NavItem to='/' label='Summary' onClick={() => console.log('Click')} />
        <NavGroup header='FlexiPay'>
          <NavItem
            to='/pay'
            iconName='call-made'
            label='Pay'
            onClick={() => console.log('Click')}
          />
          <NavItem
            to='/payment-history'
            iconName='history'
            label='Payment History'
            onClick={() => console.log('Click')}
          />
          <NavItem
            to='/transactions'
            iconName='transactions'
            label='Transactions'
            onClick={() => console.log('Click')}
          />
          <NavItem
            to='/Repayments'
            iconName='calendar-today'
            label='Repayments'
            onClick={() => console.log('Click')}
          />
          <NavItem
            to='/card'
            iconName='credit-card'
            label='Card'
            onClick={() => console.log('Click')}
          />
          <NavItem
            to='/request-payment'
            iconName='call-received'
            label='Get Paid'
            onClick={() => console.log('Click')}
          />
          <NavItem
            to='/flexipay-rewards'
            iconName='cashback'
            label='Cashback'
            onClick={() => console.log('Click')}
          />
          <NavItem
            to='/refer-business'
            iconName='group-add'
            label='Refer business'
            onClick={() => console.log('Click')}
          />
          <NavItem
            to='/Repayments'
            iconName='group-add'
            label='Disabled Icon'
            disabled
            onClick={() => console.log('Click')}
          />
        </NavGroup>
        <NavGroup header='Business Loans'>
          <NavItem
            to='/overview'
            iconName='view-agenda'
            label='Overview'
            onClick={() => console.log('Click')}
          />
        </NavGroup>
        <NavItem
          to='/support'
          label='Support'
          onClick={() => console.log('Click')}
        />
        <NavItem
          to='/signout'
          label='Sign out'
          onClick={() => console.log('Click')}
        />
      </div>
      <h2>Error</h2>
      <Error
        title='Error Title'
        showPageError={true}
        message='This is the error message'
        icon='info'
      />
      <h2>Footer/SiteLinks</h2>
      <div className={styles.footer}>
        <SiteLinks linksData={LEGAL_LINKS} />
      </div>
      <h2>Form & Input</h2>
      <p>
        The form is simply a wrapper with some built-in methods. The following
        input is wrapped in a form.
      </p>
      <Form onSubmit={() => {}}>
        <Input label='Input' name='input'>
          Input children
        </Input>
      </Form>
      <h2>NoteAndError</h2>
      <NoteAndError
        note='This is a note'
        error={{ message: 'This is an error' }}
      />
      <h2>Table</h2>
      <Table
        headers={['Column 1', 'Column 2']}
        items={items}
        itemComponent={itemComponent}
      />
    </div>
  );
};

export default App;
